
.loading{
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  background-color: #433a8b;
  border-radius: 100%;
  height: 10em;
  width:10em;
  
}
.loadbox{
  align-items: center;
  background-color: #d6d5de;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
@keyframes sk-scaleout{
  0%{
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100%{
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
}
