
.form-control{
    width: 100%;  
    color: #999999; 
    border: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;   
    font-size: 24px;
    color: rgb(153, 153, 153);
    height: auto;
    padding: 21px 40px;
    border-radius: 50px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    opacity: 0.7;
    
  
}
.form-submit{
    min-width: 250px;
    
   border-color: #f8f8f8 ;
   border-width: 2px;
   border: #f8f8f8 2px solid;
    border-radius: 30px;
    padding: 21px 15px;
  
    top: 50%;

    width:200px;
    position:relative;
    font-size: 24px;
    right: 24px;
    height:auto;
    color:#f8f8f8;
  
    /* padding:15px 15px ; */
    align-items: center;
    vertical-align: auto;
    margin: 15px 15px;
    font-weight: 600;
    background-color:rgba(0, 0, 0, 0.474);
    border-style: solid;
    border-width: 0;
    border-color: #000000;
    opacity: 0.7;

}

.footer-top{
    padding: 50px 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
   
   
    width:100%;
    text-align: center;
}

.footer-bottom{
    display: flex;
    flex-wrap: wrap;
   
   
    
   
  height:auto;
    margin-top: 50px;
   
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
   
    

}
.box{
    display: block;
    block-size: 100px;
    height: 100px;
    box-sizing: content-box;
    border-color: #fd9217;
    box-shadow: #000000;
}

.footer-title{
    padding-bottom: 10px;
    font-weight: bold;
}
.footer-bottom-col{
  display: block;
margin-bottom: 10px;

 
 width:220px;
 height: 220px;
    padding:10px 10px;
}

.mapscreen{
    display: block;
    width: 80px;
    height:80px;
    block-size: 50px 50px;
    PADDING:0 20PX;
    background-color: #000000;
}
