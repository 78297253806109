@tailwind base;
@tailwind components;
@tailwind utilities;



body {
    font-family: 'Montserrat', sans-serif;
    
        overflow-x: hidden; /* Prevent horizontal overflow */
    
    
}

:root {
    --animate-duration: 4s;
    --animate-delay: 0.5s;
}  
.slick-slide > div {
    margin: 10px 10px;
}
.tagline{
    text-shadow: 0 5px 10px #0004;
}

/* .hotel-content{
    padding: 0 15px;
}
.hotel-img{
    width:100%;
    height: auto;
    border-radius: 7px;
}

.row-hotels{
    display: grid;
    width: 100%;
    margin:0 120px;
    grid-template-columns: 1fr 1fr 1fr ;
    text-align: center;
} 
*/
/* .nav-link:after {
    position: absolute;
    left: 0px;
    bottom: -15px;
    height: 5px;
    width: 0;
    background: linear-gradient(to right, #fd9217 0%, #acc711 100%);
    content: "";
    opacity: 0;
    border-radius: 16px;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
} */
.title{
   justify-content: center;
   align-items: center;
   text-align: left;
   width: max-content;
   padding: 10px 20px;
    margin-top: 10px ;
    margin-bottom: 10px ;
    
    color: #433a8b;
    font-weight: 800;
    text-shadow: 2px 4px 4px rgba(255, 255, 255, 0.177);
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;     
}
.medtab{
    transition-duration: 300ms;
    cursor: pointer;
    background-color: #433a8b;
    color: white;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1.5rem;
}
.container{
    box-shadow: 0px 1px 4px rgba(41, 51, 57, 0.5);
    padding: 10px;
    padding-bottom: 10px;
   
   
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.ktt {
    font-size: 19px !important;
    color: #433a8b;
    font-weight: 800;
    line-height: 58px;
}
.kbt{
    font-size: 22px !important;
    color: #433a8b;
    font-weight: 800;
    line-height: 58px;
}
.hoverbox{
    display: block;
    box-shadow: 2px 1px 4px rgba(41, 51, 57, 0.5);
    padding: 10px;
    padding-bottom: 10px;
    visibility: visible;
    animation-duration: 1.5s;
    animation-delay: 0.4s;
    animation-name: fadeInUp;
    width: 300px;
    height:300px;   
}

.carousel-slide {
    position: relative;    
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}
.image{
    flex:0 0 100px;    
    display: block;
    height: 220px;
    width: 360px;
   
}
.formbox{   
    padding: 12px 16px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: black;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top:4px; 
    margin-bottom: 4;
    overflow: visible;
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.btn{
    padding:21px 25px;
    margin-top:8px; 
    margin-bottom: 0;
    line-height: 20px;
    font-size:18px;
    color:  white;
    font-weight: 500;
    background:  linear-gradient(to right, #fd9217 0%, #acc711 100%) ;
    
}
.prev-button {
    left: 10px; /* Adjust as needed */
  }
  
  .next-button {
    right: 10px; /* Adjust as needed*/
  }
.searchbar{
    padding:21px 25px;
    margin-top:8px; 
    margin-bottom: 0;
    line-height: 20px;
    font-size:18px;
    color:  black;
    font-size: 500;
}

.searchbar:focus{
    outline: none;
}

/* @media (min-width: 768px) {
    .abt_div {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.abt_div{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.abtus{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
} */
@keyframes scrolltop{
    0% {
        -webkit-box-shadow: 0 0 0 0px hsla(247, 41%, 39%, 0.6);
        box-shadow: 0 0 0 0px hsla(247, 41%, 39%, 0.6);
    }
    100% {
        -webkit-box-shadow: 0 0 0 15px rgba(67, 58, 139, 0.01);
        box-shadow: 0 0 0 15px rgba(67, 58, 139, 0.01);
    }
}